import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import * as React from 'react'

import { classNames } from '~/utils/class-names'

export const TooltipProvider = TooltipPrimitive.Provider
export const TooltipRoot = TooltipPrimitive.Root
export const TooltipTrigger = TooltipPrimitive.Trigger

export const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, children, ...props }, ref) => (
  <TooltipPrimitive.Content
    className={classNames(
      'bg-surface-primary',
      'data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade',
      'select-none rounded-md border px-[15px] py-[10px] text-sm leading-none',
      'shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] will-change-[transform,opacity]',
      className,
    )}
    ref={ref}
    sideOffset={sideOffset}
    {...props}
  >
    {children}
    <TooltipPrimitive.Arrow className="fill-white text-white dark:fill-zinc-900" />
  </TooltipPrimitive.Content>
))
TooltipContent.displayName = TooltipPrimitive.Content.displayName

type TooltipProps = TooltipPrimitive.TooltipProps & {
  label?: React.ReactNode | React.ReactElement
}

export function Tooltip({ children, label }: TooltipProps) {
  return (
    <TooltipRoot>
      <TooltipTrigger asChild>
        <span className="inline-flex">{children}</span>
      </TooltipTrigger>
      <TooltipContent align="start">{label}</TooltipContent>
    </TooltipRoot>
  )
}
